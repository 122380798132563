li[aria-selected="true"] > p {
  font-size: 25px !important;
}
#tahuns {
  justify-content: center;
  display: inline-grid;
  border-radius: 10px;
  width: 100%;
  /* height: 100%; */
}

.sc-bdVaJa > span {
  display: none !important;
}
.sc-bdVaJa > p {
  padding: 0px !important;
  margin: 0px !important;
}
.gVufvl:focus {
    outline: 0px !important;
}
.gpTRCZ {
    justify-content: center !important;
}
