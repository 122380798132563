
@keyframes run {
  0% {
    left: 0;
  }
  50% { 
    left: 30%; 
   }
  100% {
    left: 70%;     
  }
}
#loadProgress {
  position: absolute;
  bottom: 100px;
  animation: linear infinite;
  animation-name: run;
  animation-duration: 3s;
}


