@font-face {
  font-family: Poppins;
  src: url("../../font/Poppins/Poppins-Regular.ttf");
}

.paraBox {
  height: auto;
  text-align: left;
}

.title {
  font-size: 30.781px;
  font-weight: 700;
}

.subtitle {
  font-size: medium;
}

.img {
  height: 289px;
}

.checkbox {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: fit-content;
}

.checkboxTitle {
  text-align: left;
  display: flex;
  align-items: center;
  font-family: Poppins;
  font-size: 12px;
}

.columnBox {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  padding-left: 20px;
  padding-right: 20px;
  bottom: 10px;
  left: 0;
  width: 100%;
}
.scaleButton {
  animation: scaling 1s forwards;
  background-color: #1b7a83;
  color: #ffffff;
  position: fixed;
  right: 35px;
  bottom: 18px;
  border-radius: 50px;
  display: block;
  width: 40px;
  height: 40px;
  animation-delay: 0.5s;
  transform-origin: bottom right;
}
.fadeout {
  animation: fadingout 0.5s forwards;
}
@keyframes fadingout {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes scaling {
  0% {
    border-radius: 30px;
  }
  100% {
    width: 100%;
    height: 100vh;
    bottom: 0px;
    right: 0px;
  }
}
