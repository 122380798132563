.cardAccordion {
  background-color: #b5dbde !important;
}

.cardDetailAccordion {
  background-color: #20929d !important;
  color: white;
  max-height: 100px;
  overflow: scroll;
}
.custom-divider {
  background-color: #20929D33; /* Change this to the desired color */
  height: 2px; 
}

.trans {
  width: 100%;
  transition-duration: 2s !important;
  transition-timing-function: linear;
  transition-delay: 0s;
  transition-property: all !important;
}
.listTipeCard{
  display: none;
  transition: all 0.5s linear;
}
.centerText {
  position: absolute;
  top: 50%;
  font-size: 14px;
  font-family: "poppins";
  left: 20%;
  transform: translate(-20%, -50%);
}
.show {
  display: block;
}
.hide {
  display: none;
}
.alpha-list {
  width: 350px;
  height: 400px;
}


