.boxStep{
  margin-top: -35px;
  z-index: 99999 !important;
}
.borderFade{
  background-color: transparent ;
  position: absolute;
  display: block;
  z-index: 4;
  top: 0;
  /* background-color: ; */
  width: 100%;
}

.borderFade>div>div>div{
  padding-top: 48%;
}

.imageLokasi{
  position: relative;
  width: 100%;
  z-index: 1;
  display: block !important;
}
.MuiPaper-root{
  color: transparent !important;
}
.textSwiper{
  position: relative;
  display: block;
  left: 0;
  font-weight: 600;
  width: 100%;
  font-family: 'Poppins';
  color: #20929D;
}
.textSwiper>div>div>div{
  padding-top: 20px;
}