/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    position: absolute !important;
    margin: 0 auto !important;
    width: 100%;
    background-color: black !important;
    max-width: 100% !important;
} */
.infoText {
  font-size: 14px;
  text-align: left;
}
.swipe {
  z-index: 999;
  position: static !important;
}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.frame::-webkit-scrollbar:vertical {
  width: 5px;
}

/* .frame::-webkit-scrollbar:horizontal {
  height: 11px;
} */

.frame::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 1px solid white; /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, 0.5);
}

.buttonCheckbox {
  border: 1px solid blue;
  margin: 0 !important;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.swipeRight {
  border-radius: 16px;
  width: 300px;
  height: 100%;
  z-index: 11;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgba(0, 128, 128, 0.6);
}
.swipeLeft {
  border-radius: 16px;
  width: 300px;
  height: 100%;
  z-index: 11;
  position: absolute;
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgba(128, 10, 0, 0.6);
}

.hidden {
  display: none;
}

.showSwipe {
  display: flex;
}
