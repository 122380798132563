@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

#root {
  text-align: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  /* background: linear-gradient(#fff, #999); */
  background:#1B7A83;
}

* {
  user-select: none;
}

/* #root>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.app {
  overflow: hidden;
}

.app>div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row !important;
}

.row>* {
  margin: 5px;
}

h1 {
  font-family: 'Poppins';
  color: black;
  text-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
}


h3 {
  font-family: 'Poppins';
  color: black;
}

h2 {
  color: black;
}

.swipe {
  position: absolute;
  width: 100%;
}

.gridContainer{
  max-width: 280px;
  
}

.cardContainer {
  width: 90vw;
  max-width: 300px;
  position: relative;
  height: 300px;
}

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 300px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

.cardContent {
  width: 100%;
  height: 100%;
}

.swipe:last-of-type {

}

.card h3 {
  position: absolute;
  bottom: 0;
  margin: 10px;
  color: #fff;
}

.infoText {
  width: 100%;
  justify-content: center;
  display: flex;
  color: black;
  animation-name: popup;
  animation-duration: 800ms;
}

.buttons {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 625px) {
  .buttons {
    flex-direction: row;
  }
}

.buttons button {
  flex-shrink: 0;
  transition: 200ms;
  margin: 20px;
  border-radius: 30px;

}

.buttons button:hover {
  transform: scale(1.05);
}

@keyframes popup {
  0%   { transform: scale(1,1) }
  10%  { transform: scale(1.1,1.1) }
  30%  { transform: scale(.9,.9) }
  50%  { transform: scale(1,1) }
  57%  { transform: scale(1,1) }
  64%  { transform: scale(1,1) }
  100% { transform: scale(1,1) }
}