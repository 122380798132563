.centerTextHome {
  position: absolute;
  top: 50%;
  color: white;
  font-size: 18px;
  text-align: left;
  font-family: "poppins";
  left: 10%;
  transform: translate(-20%, -50%);
  line-height: 1.5rem;
}
/* .sup{
  background-color: #F2994A;
  border-radius: 12px;
  font-size: 12px;
  padding: 1px 10px;
  margin-left: -5px;
} */
.centerImages {
  position: absolute;
  top: 67%;
  color: white;
  width: 20%;
  height: 45%;
  font-size: 20px;
  text-align: left;
  font-family: "poppins";
  right: 7%;
  transform: translate(-20%, -50%);
  line-height: 2.2rem;
}