.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  height: 100%;
  width: 100%;
}

.beforePick {
  min-height: 85vh;
  display: table;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: min-height 1s ease-in-out;
}

.afterPick {
  min-height: 26vh;
  display: table;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: min-height 1s ease-in-out;
  /* transition-delay: 1s; */
}
.wave {
  display: inline-block;
  overflow: hidden;
  animation: wave 1s linear infinite;
}
.wave1 {
  display: inline-block;
  overflow: hidden;
  animation: wave 1s linear infinite;
  animation-delay: 0.2s;
}
.wave2 {
  display: inline-block;
  overflow: hidden;
  animation: wave 1s linear infinite;
  animation-delay: 0.4s;
}
.imageAfterMap {
  width: 60% !important;
  transition: width 1s ease-in-out !important;
}
.imageAfter {
  width: 70% !important;
  transition: width 1s ease-in-out !important;
}
.imageBefore {
  width:80% !important;
  transition: width 1s ease-in-out !important;
}
@keyframes wave {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(8px);
  }
}